import {
  Grid,
  Container,
  Typography,
  Icon,
  Card,
  CardContent,
  TextField,
  Divider,
  InputAdornment,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { useEffect, useState } from "react";
import routes from "../routes.json";
import { Angulo } from "../types";

const { base, certificados, estadisticas } = routes;
export const ConfiguracionPage = () => {
  return (
    <Container maxWidth={"md"}>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1">
            Configuración
          </Typography>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" xs={12} lg={12} mt={5} spacing={2}>
          <Divider />
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<Icon>expand_more</Icon>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Datos de la empresa</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} lg={6}>
                      <TextField sx={{ width: "100%" }} label="Razón social" variant="outlined" type="text" />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField sx={{ width: "100%" }} label="NIT" variant="outlined" type="number" />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField sx={{ width: "100%" }} label="Teléfono" variant="outlined" type="number" />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField sx={{ width: "100%" }} label="Dirección" variant="outlined" type="number" />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <TextField sx={{ width: "100%" }} label="Email" variant="outlined" type="number" />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <TextField sx={{ width: "100%" }} label="Whatsapp" variant="outlined" type="phone" />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<Icon>expand_more</Icon>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Patrones de calibración</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid container item md={6}>
                        <Card variant="outlined">
                          <CardContent>
                            <Typography variant="body1" mb={2}>
                              Angular
                            </Typography>

                            <AnguloInput onChange={(angulo: Angulo) => console.log(angulo)} />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid container item md={6}>
                        <Card variant="outlined">
                          <CardContent>
                            <Typography variant="body1" mb={2}>
                              Distancia
                            </Typography>
                            <Grid container item spacing={2}>
                              <Grid item xs={6}>
                                <TextField
                                  InputProps={{
                                    endAdornment: <InputAdornment position="start">m</InputAdornment>,
                                  }}
                                  label="Patrón 1"
                                  variant="outlined"
                                  type="number"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  InputProps={{
                                    endAdornment: <InputAdornment position="start">m</InputAdornment>,
                                  }}
                                  label="Patrón 2"
                                  variant="outlined"
                                  type="number"
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<Icon>expand_more</Icon>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Fases de calibración</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item mt={2} md={6}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography variant="body1">Fases 1 Angular vertical</Typography>
                          <Grid container item mt={4}>
                            <AnguloInput onChange={(angulo: Angulo) => console.log(angulo)} />
                          </Grid>
                          <Grid container item mt={4}>
                            <AnguloInput onChange={(angulo: Angulo) => console.log(angulo)} />
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item mt={2} md={6}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography variant="body1">Fases 1 Angular horizontal</Typography>
                          <Grid container item mt={4}>
                            <AnguloInput onChange={(angulo: Angulo) => console.log(angulo)} />
                          </Grid>
                          <Grid container item mt={4}>
                            <AnguloInput onChange={(angulo: Angulo) => console.log(angulo)} />
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item mt={2} md={6}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography variant="body1">Fases 1 Distancia Horizontal</Typography>
                          <Grid container spacing={2}>
                            <Grid item mt={2} xs={12}>
                              <TextField
                                fullWidth
                                InputProps={{
                                  endAdornment: <InputAdornment position="start">m</InputAdornment>,
                                }}
                                label="Patrón"
                                variant="outlined"
                                type="number"
                              />
                            </Grid>
                            <Grid item mt={2} xs={12}>
                              <TextField
                                fullWidth
                                InputProps={{
                                  endAdornment: <InputAdornment position="start">m</InputAdornment>,
                                }}
                                label="Patrón"
                                variant="outlined"
                                type="number"
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

const AnguloInput: React.FC<{ onChange: Function }> = ({ onChange }) => {
  const [angulo, setAngulo] = useState<Angulo>([0, 0, 0]);

  useEffect(() => {
    onChange(angulo);
  }, [angulo]);

  return (
    <Grid container item spacing={2}>
      <Grid item xs={4}>
        <TextField
          InputProps={{
            endAdornment: <InputAdornment position="start">°</InputAdornment>,
          }}
          label="Grados"
          variant="outlined"
          type="number"
          value={angulo[0]}
          onChange={(e: any) => {
            setAngulo([parseInt(e.target.value), angulo[1], angulo[2]]);
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          InputProps={{
            endAdornment: <InputAdornment position="start">'</InputAdornment>,
          }}
          label="Minutos"
          variant="outlined"
          type="number"
          value={angulo[1]}
          onChange={(e: any) => {
            setAngulo([angulo[0], parseInt(e.target.value), angulo[2]]);
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          InputProps={{
            endAdornment: <InputAdornment position="start">"</InputAdornment>,
          }}
          label="Segundos"
          variant="outlined"
          type="number"
          value={angulo[2]}
          onChange={(e: any) => {
            setAngulo([angulo[0], angulo[1], parseInt(e.target.value)]);
          }}
        />
      </Grid>
    </Grid>
  );
};
