import {
  Grid,
  Paper,
  InputBase,
  Container,
  IconButton,
  Typography,
  Icon,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Alert,
  AlertTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { useFetch } from "../hooks/useFetch.hook";
import routes from "../routes.json";
import { addCommas, consultarNIT } from "../utils";

const { base, clientePorNIT, clientes } = routes;
export const ClientesPage = () => {
  const [busqueda, setBusqueda] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  const { loading, error, data } = useFetch(`${base}${clientes}?&page=${page}&limit=${limit}&busqueda=${busqueda}`);

  const debounced = useDebouncedCallback((value) => {
    setBusqueda(value);
  }, 1000);

  const [isOpen, setIsOpen] = useState(false);
  const [cliente, setCliente] = useState<any>();
  return (
    <Container maxWidth={"md"}>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1">
            Clientes
          </Typography>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" xs={12} lg={12} mt={5} spacing={2}>
          <Grid item xs={12} lg={8}>
            <Paper style={{ borderRadius: 18, padding: "0.2em" }}>
              <Grid item container direction="row" justifyContent="center" alignItems="center" xs={12} lg={12}>
                <IconButton disabled sx={{ p: "10px" }} aria-label="menu">
                  <Icon>people</Icon>
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Nombre o NIT"
                  inputProps={{ "aria-label": "Nombre del cliente o NIT" }}
                  onChange={(e: any) => {
                    debounced(e.target.value);
                  }}
                />
                <IconButton sx={{ p: "10px" }} aria-label="menu">
                  <Icon> search</Icon>
                </IconButton>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "100%" }}
              onClick={() => {
                setIsOpen(true);
                setCliente(undefined);
              }}
            >
              Nuevo Cliente
            </Button>
          </Grid>
        </Grid>

        <Grid container item xs={12} mt={2}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  {/* <TableCell /> */}
                  <TableCell align="left" sx={{ fontWeight: "bold", cursor: "pointer" }} sortDirection={"asc"}>
                    NIT
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                    <Icon sx={{ fontSize: "1em", mb: -0.2 }}>person</Icon> Razón Social
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    <Icon sx={{ fontSize: "1em", mb: -0.2 }}>phone</Icon> Teléfono
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                    <Icon sx={{ fontSize: "1em", mb: -0.2 }}>email</Icon> Email
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody sx={{}}>
                {loading && (
                  <TableRow>
                    <TableCell align="center" colSpan={6}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}

                {data?.count &&
                  data?.clientes?.map((cliente: any, index: number) => (
                    <TableRow
                      key={index}
                      hover
                      sx={{ "& > *": { borderBottom: "unset" }, cursor: "pointer" }}
                      onClick={() => {
                        setCliente(cliente);
                        setIsOpen(true);
                      }}
                    >
                      <TableCell component="th" scope="row" width={"20%"}>
                        {addCommas(cliente.NIT)}
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2" component="p" textAlign={"left"}>
                          {cliente.nombre}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{cliente.telefono}</TableCell>

                      <TableCell align="left">{cliente.email}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage="Clientes por página"
            labelDisplayedRows={({ from, to, count }) => {
              return `Del ${from} al ${to} de  ${count !== -1 ? count : `more than ${to}`}`;
            }}
            rowsPerPageOptions={[2, 5, 10, 15, 20]}
            count={busqueda ? data?.clientes?.length : data?.count}
            rowsPerPage={limit}
            page={page}
            onPageChange={(event, page) => {
              setPage(page);
            }}
            onRowsPerPageChange={(event) => setLimit(parseInt(event.target.value))}
          />
        </Grid>
      </Grid>
      <NuevoClienteModal
        clienteI={cliente}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          setCliente({});
        }}
      />
    </Container>
  );
};

const NuevoClienteModal: React.FC<{ clienteI?: any; open: boolean; onClose: any }> = ({ open, onClose, clienteI }) => {
  const navigate = useNavigate();
  const [cliente, setCliente] = useState<any>();

  const [alert, setAlert] = useState<any>();

  useEffect(() => {
    if (clienteI) setCliente(clienteI);
  }, [clienteI]);

  const handleClose = () => {
    setCliente({});
    setAlert(undefined);
    onClose();
  };

  const eliminarCliente = async () => {
    fetch(`${base}${clientes}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(cliente),
    })
      .then((res) => res.json())
      .then((e) => {
        setAlert({
          color: "success",
          mensaje: "Cliente eliminado correctamente",
          titulo: `${e.cliente.nombre} fue eliminiado`,
        });
        navigate(0);
      })
      .catch((e) => window.alert(e));
  };

  const crearCliente = async (update?: boolean) => {
    console.log(cliente);
    fetch(`${base}${clientes}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(cliente),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
        else throw "El cliente no pudo ser creado";
      })
      .then((e) => {
        setAlert({ titulo: "Cliente creado", mensaje: "Se guardó la información de este cliente", color: "success" });
        //reload browser window
        navigate(0);
      })
      .catch((err) => {
        setAlert({ titulo: "Error", mensaje: err, color: "error" });
      });
  };

  const debounced = useDebouncedCallback((value) => {
    setCliente({ ...cliente, NIT: value });
  }, 600);

  return (
    <Dialog open={open} onClose={handleClose} onBackdropClick={handleClose}>
      <DialogTitle>
        <b>{clienteI ? "Actualizar " : "Crear "}cliente</b>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Razón social"
          type="text"
          fullWidth
          autoComplete="off"
          variant="standard"
          defaultValue={cliente?.nombre}
          onChange={(e) => {
            setCliente({ ...cliente, nombre: e.target.value });
          }}
        />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="NIT"
          type="number"
          fullWidth
          autoComplete="off"
          variant="standard"
          defaultValue={cliente?.NIT}
          onChange={(e) => debounced(e.target.value)}
        />
        <br />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Teléfono"
          type="phone"
          fullWidth
          autoComplete="off"
          variant="standard"
          defaultValue={cliente?.telefono}
          onChange={(e) => {
            setCliente({ ...cliente, telefono: e.target.value });
          }}
        />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Dirección"
          type="address"
          fullWidth
          autoComplete="off"
          variant="standard"
          defaultValue={cliente?.direccion}
          onChange={(e) => {
            setCliente({ ...cliente, direccion: e.target.value });
          }}
        />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email"
          type="email"
          fullWidth
          autoComplete="off"
          variant="standard"
          defaultValue={cliente?.email}
          onChange={(e) => {
            setCliente({ ...cliente, email: e.target.value });
          }}
        />
        <br /> <br />
        {alert && (
          <Alert severity={alert.color}>
            <AlertTitle>
              <b>{alert.titulo}</b>
            </AlertTitle>
            {alert.mensaje}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button sx={{ mr: 8 }} color="error" variant="contained" onClick={eliminarCliente}>
          <Icon>delete</Icon>
        </Button>
        <Button onClick={handleClose}>Cancelar</Button>

        <Button
          color="primary"
          variant="contained"
          onClick={() =>
            consultarNIT(cliente?.NIT)
              .then((e) => crearCliente(true))
              .catch((e) => window.alert(e.message))
          }
        >
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
