import {
  Grid,
  Paper,
  InputBase,
  Container,
  IconButton,
  Typography,
  Icon,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Alert,
  AlertTitle,
} from "@mui/material";
import { useState } from "react";
import { useFetch } from "../hooks/useFetch.hook";
import { useDebouncedCallback } from "use-debounce";

export const TecnicosPage = () => {
  const [busqueda, setBusqueda] = useState("");

  const { loading, error, data } = useFetch(`http://localhost/tecnicos?NIT=${busqueda}&nombre=${busqueda}`);

  const debounced = useDebouncedCallback((value) => {
    setBusqueda(value);
  }, 1000);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container maxWidth={"md"}>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1">
            Técnicos
          </Typography>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" xs={12} lg={12} mt={5} spacing={2}>
          <Grid item xs={12} lg={8}>
            <Paper style={{ borderRadius: 18, padding: "0.2em" }}>
              <Grid item container direction="row" justifyContent="center" alignItems="center" xs={12} lg={12}>
                <IconButton disabled sx={{ p: "10px" }} aria-label="menu">
                  <Icon>people</Icon>
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Nombre o Cédula"
                  inputProps={{ "aria-label": "Nombre del técnico o cédula" }}
                  onChange={(e: any) => {
                    debounced(e.target.value);
                  }}
                />
                <IconButton sx={{ p: "10px" }} aria-label="menu">
                  <Icon> search</Icon>
                </IconButton>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Button variant="contained" color="primary" sx={{ width: "100%" }} onClick={() => setIsOpen(true)}>
              Nuevo Técnico
            </Button>
          </Grid>
        </Grid>
        {(data?.length &&
          data?.map((tecnico: any, index: number) => (
            <Grid item xs={12} mt={4} key={index}>
              <TecnicoCard tecnico={{ nombre: tecnico.nombre, NIT: 901251885, telefono: 3008888888 }} />
            </Grid>
          ))) ||
          ""}
      </Grid>
      <NuevoTecnicoModal open={isOpen} onClose={() => setIsOpen(false)} />
    </Container>
  );
};

const TecnicoCard: React.FC<{ tecnico: { nombre: string; NIT: number; telefono: number } }> = ({ tecnico }) => {
  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="h5" component="h2">
              {tecnico.nombre}
            </Typography>
            <Typography variant="caption" component="h2">
              Razón social
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h5" component="h2">
              {tecnico.NIT}
            </Typography>
            <Typography variant="caption" component="h2">
              NIT
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h5" component="h2">
              {tecnico.telefono}
            </Typography>
            <Typography variant="caption" component="h2">
              Teléfono
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const NuevoTecnicoModal: React.FC<{ open: boolean; onClose: any }> = ({ open, onClose }) => {
  const [tecnico, settecnico] = useState<any>({});
  const { loading, error, data } = useFetch(`http://localhost/Tecnicos?NIT=${tecnico?.NIT}&nombre=${tecnico?.nombre}`);

  const debounced = useDebouncedCallback((value) => {
    settecnico({ ...tecnico, cedula: value });
  }, 1000);

  return (
    <Dialog open={open} onClose={onClose} onBackdropClick={onClose}>
      <DialogTitle>
        <b>Crear técnico nuevo</b>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Nombre"
          type="text"
          fullWidth
          autoComplete="off"
          variant="standard"
          defaultValue={tecnico?.nombre}
          onChange={(e) => {
            settecnico({ ...tecnico, nombre: e.target.value });
          }}
        />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Cédula"
          type="number"
          fullWidth
          autoComplete="off"
          variant="standard"
          defaultValue={tecnico?.cedula}
          onChange={(e) => debounced(e.target.value)}
        />
        <br />
        {loading && <CircularProgress />}
        <br /> <br />
        {!!data?.length && (
          <Alert
            severity="error"
            action={
              <Button color="warning" variant="contained" size="small">
                Actualizar
              </Button>
            }
          >
            <AlertTitle>
              El tecnico con NIT: <b>{tecnico?.cedula}</b> ya existe.
            </AlertTitle>
            ¿Quieres actualizar sus datos?
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        {!data?.length && <Button onClick={onClose}>Crear tecnico</Button>}
      </DialogActions>
    </Dialog>
  );
};
