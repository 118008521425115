//create a custom context using useContext hook and provider  to use the auth hook

import React, { useEffect, useState } from "react";
import { parseJwt } from "../utils";
import routes from "../routes.json";
const { base, login } = routes;

interface IAuthContext {
  children?: React.ReactNode;
  user?: any;
  login: (user: any, password: string) => void;
  logout: () => void;
}

export const AuthContextDefaultValue: IAuthContext = {
  children: null,
  user: null,
  login: () => {
    console.log("NO IMPLEMENTADO");
  },
  logout: () => {
    console.log("NO IMPLEMENTADO");
  },
};

export const AuthContext = React.createContext<IAuthContext>(AuthContextDefaultValue);

export const AuthProvider = ({ children }: any) => {
  const [token, setToken] = useState<any>(localStorage.getItem("token") || undefined);
  const [user, setUser] = useState<any>(
    localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") as string) : undefined
  );

  //read token from local storage and get user data
  useEffect(() => {
    if (token) {
      let decoded = parseJwt(token);
      //chech if token is expired 18h
      if (decoded.exp * 1000 < Date.now()) {
        setUser(undefined);
        setToken(undefined);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        alert("Su sesión ha expirado");
      } else {
        setUser(decoded?.user);
        setToken(decoded?.token);
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(parseJwt(token)?.user));
      }
    }
  }, [token]);

  const logout = () => {
    setUser(undefined);
    setToken(undefined);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };

  const logIn = (username: string, password: string) => {
    fetch(`${base}${login}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
      mode: "cors",
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setUser(data.user);
          setToken(data.token);
        });
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        throw Error("No se pudo iniciar sesion");
        //clean local storage
      }
    });
  };
  return (
    <AuthContext.Provider
      value={{
        user,
        login: logIn,
        logout,
      }}
    >
      {" "}
      {children}
    </AuthContext.Provider>
  );
};
