import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Icon,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useFetch } from "../hooks/useFetch.hook";
import { NuevoCertificadoModal } from "../modals/certificado.modal";
import routes from "../routes.json";

const { base, certificados, estadisticas } = routes;

export const CertificadosPage = () => {
  const [busqueda, setBusqueda] = useState({
    cliente: null,
    numerocertificado: null,
  });

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [sort, setSort] = useState({
    field: "numero",
    order: "desc",
  });
  const { loading, data } = useFetch(
    `${base}${certificados}?sort=${JSON.stringify(sort)}&page=${page}&limit=${limit}&clienteBusqueda=${
      busqueda.cliente || ""
    }&numero=${busqueda.numerocertificado || ""}`
  );
  const { data: stats } = useFetch(`${base}${estadisticas}`);

  const debounced = useDebouncedCallback((value) => {
    setBusqueda(value);
  }, 600);

  const [isOpen, setIsOpen] = useState(false);
  const [certificadoEditar, setCertificadoEditar] = useState<any>();
  return (
    <Container maxWidth={"lg"}>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1">
            Certificados
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          item
          justifyContent="center"
          alignItems="center"
          xs={12}
          lg={12}
          mt={5}
          spacing={2}
        >
          <Grid item xs={12} lg={3}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  {stats?.emitidos}
                </Typography>
                <Typography variant="caption" component="h2">
                  Certificado emitidos
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Card
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.location.href = `/clientes`;
              }}
            >
              <CardContent>
                <Typography variant="h5" component="h2">
                  {stats?.clientes}
                </Typography>
                <Typography variant="caption" component="h2">
                  Clientes
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Card
              style={{ cursor: "pointer" }}
              onClick={() =>
                setSort((prev) => ({
                  ...prev,
                  field: "fechas.proxima",
                  order: "asc",
                }))
              }
            >
              <CardContent>
                <Typography variant="h5" component="h2">
                  {stats?.vencenEn30}
                </Typography>
                <Typography variant="caption" component="h2">
                  Vencen en {"<"} 30 días
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  -
                </Typography>
                <Typography variant="caption" component="h2">
                  cuentas de cobro pendientes
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item container spacing={2} mt={4}>
            <Grid item xs={12} lg={3}>
              <Paper style={{ borderRadius: 18, padding: "0.2em" }}>
                <Grid item container direction="row" justifyContent="center" alignItems="center" xs={12} lg={12}>
                  <IconButton disabled sx={{ p: "10px" }} aria-label="menu">
                    <Icon>articleper</Icon>
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Número de certificado"
                    inputProps={{ "aria-label": "Número de certificado " }}
                    onChange={(e: any) => {
                      debounced({
                        numerocertificado: e.target.value || "",
                      });
                    }}
                  />
                  <IconButton sx={{ p: "10px" }} aria-label="menu">
                    <Icon> search</Icon>
                  </IconButton>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Paper style={{ borderRadius: 18, padding: "0.2em" }}>
                <Grid item container direction="row" justifyContent="center" alignItems="center" xs={12} lg={12}>
                  <IconButton disabled sx={{ p: "10px" }} aria-label="menu">
                    <Icon>person</Icon>
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Nombre o NIT del cliente"
                    inputProps={{ "aria-label": "  nombre o NIT del cliente" }}
                    onChange={(e: any) => {
                      debounced({
                        cliente: e.target.value || "",
                      });
                    }}
                  />
                  <IconButton sx={{ p: "10px" }} aria-label="menu">
                    <Icon> search</Icon>
                  </IconButton>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "100%" }}
                onClick={() => {
                  setCertificadoEditar(null);
                  setIsOpen(true);
                }}
              >
                Nuevo certificado
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Paper
            style={{
              overflowX: "auto",
              marginRight: "auto",
              marginLeft: "auto",
              marginTop: "50px",
              padding: "10px",
              margin: "10px",
              width: "100%",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell /> */}
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", cursor: "pointer" }}
                    sortDirection={"asc"}
                    onClick={() =>
                      setSort((prev) => ({ ...prev, field: "numero", order: prev.order == "asc" ? "desc" : "asc" }))
                    }
                  >
                    No.
                    <TableSortLabel active={sort.field == "numero"} direction={sort.order as any}></TableSortLabel>
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                    Cliente
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Equipo
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Serial
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", cursor: "pointer" }}
                    sortDirection={"asc"}
                    onClick={() =>
                      setSort((prev) => ({
                        ...prev,
                        field: "fechas.calibracion",
                        order: prev.order == "asc" ? "desc" : "asc",
                      }))
                    }
                  >
                    Calibración
                    <TableSortLabel
                      active={sort.field == "fechas.calibracion"}
                      direction={sort.order as any}
                    ></TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", cursor: "pointer" }}
                    sortDirection={"asc"}
                    onClick={() =>
                      setSort((prev) => ({
                        ...prev,
                        field: "fechas.proxima",
                        order: prev.order == "asc" ? "desc" : "asc",
                      }))
                    }
                  >
                    Vence
                    <TableSortLabel
                      active={sort.field == "fechas.proxima"}
                      direction={sort.order as any}
                    ></TableSortLabel>
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Técnico
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody sx={{}}>
                {loading && (
                  <TableRow>
                    <TableCell align="center" colSpan={6}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}

                {data?.certificados?.map((row: any, index: number) => (
                  <TableRow
                    key={index}
                    hover
                    sx={{
                      "& > *": { borderBottom: "unset" },
                      cursor: "pointer",
                      backgroundColor: (() => {
                        const diff = moment(row.fechas.proxima).diff(moment(), "days");
                        return diff < 30;
                      })()
                        ? "#ffca0047"
                        : "unset",
                    }}
                    onClick={() => {
                      setCertificadoEditar(row);
                      setIsOpen(true);
                    }}
                  >
                    <TableCell align="center">{row.numero}</TableCell>

                    <TableCell component="th" scope="row" width={"20%"}>
                      {row?.cliente?.nombre}
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2" component="p" textAlign={"center"}>
                        {row?.equipo?.marca} <br /> {row?.equipo?.modelo}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">{row?.equipo.serial}</TableCell>
                    <TableCell align="center">{moment(row?.fechas?.calibracion).format("DD-MM-YYYY")}</TableCell>
                    <TableCell align="center">{moment(row?.fechas?.proxima).format("DD-MM-YYYY")}</TableCell>
                    <TableCell align="center">José Luis García</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TablePagination
                    labelRowsPerPage="Certificados por página"
                    labelDisplayedRows={({ from, to, count }) => {
                      return `Del ${from} al ${to} de  ${count !== -1 ? count : `more than ${to}`}`;
                    }}
                    rowsPerPageOptions={[2, 5, 10, 15, 20]}
                    count={data?.count || 0}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={(event, page) => {
                      setPage(page);
                    }}
                    onRowsPerPageChange={(event) => setLimit(parseInt(event.target.value))}
                  />
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>

      <NuevoCertificadoModal
        certificadoI={certificadoEditar}
        isOpen={isOpen}
        onClose={() => {
          if (certificadoEditar?._id) setCertificadoEditar(null);
          //reload page
          window.location.reload();
          setIsOpen(false);
        }}
      />
    </Container>
  );
};

export const CertificadoCard: React.FC<{ certificado: any }> = ({ certificado }) => {
  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={12} lg={4}>
            <Typography variant="body1" component="h2">
              Certificado No.{certificado.numero}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} mt={2}>
            <Typography variant="caption" component="h2">
              {certificado.cliente.nombre}
            </Typography>
            <Typography variant="body2" component="h2">
              NIT: {certificado.cliente.NIT}
            </Typography>
            <Typography variant="caption" component="h2">
              Cliente
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Typography variant="h5" component="h2">
              {certificado.fecha}
            </Typography>
            <Typography variant="caption" component="h2">
              Fecha de calibración
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
