import { Grid, Paper, InputBase, Container, IconButton, Icon, Button } from "@mui/material";
import { stringify } from "querystring";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/auth.context";
import routes from "../routes.json";
import { useNavigate } from "react-router-dom";
const { base, certificados, estadisticas } = routes;
export const LoginPage = () => {
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");

  //perform a login using fetch  and sending via form urlencoded data
  const { user, login } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      navigate("/certificados");
    }
  }, [user]);
  return (
    <Container maxWidth={"xs"}>
      <Grid container item xs={12} mt={16}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <img src="./logoDarkMode.png" alt="Measurement Equipment" />
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          xs={12}
          lg={12}
          mt={5}
          spacing={2}
        >
          <Grid item xs={12} lg={12}>
            <Paper style={{ borderRadius: 18, padding: "0.2em" }}>
              <Grid item container direction="row" justifyContent="center" alignItems="center" xs={12} lg={12}>
                <IconButton disabled sx={{ p: "10px" }} aria-label="menu">
                  <Icon>people</Icon>
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Cédula del técnico"
                  inputProps={{ "aria-label": "Cédula del técnico" }}
                  onChange={(e: any) => {
                    setUsername(e.target.value);
                  }}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Paper style={{ borderRadius: 18, padding: "0.2em" }}>
              <Grid item container direction="row" justifyContent="center" alignItems="center" xs={12} lg={12}>
                <IconButton disabled sx={{ p: "10px" }} aria-label="menu">
                  <Icon>lock</Icon>
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Contraseña"
                  type="password"
                  inputProps={{ "aria-label": "Contraseña" }}
                  onChange={(e: any) => {
                    setPassword(e.target.value);
                  }}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "100%" }}
              onClick={() => login(username, password)}
            >
              Iniciar sesión
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
