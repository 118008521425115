import {
  Grid,
  Paper,
  InputBase,
  Container,
  IconButton,
  Typography,
  Icon,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Alert,
  AlertTitle,
  Select,
  InputLabel,
  MenuItem,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Avatar,
  Autocomplete,
  Menu,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFetch } from "../hooks/useFetch.hook";
import { useDebouncedCallback } from "use-debounce";
import routes from "../routes.json";
import { useNavigate } from "react-router-dom";
import { convertToObject } from "typescript";

const { base, equipos, marcas } = routes;
export const EquiposPage = () => {
  const [busqueda, setBusqueda] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState<number | null>(5);
  const { loading, error, data } = useFetch(`${base}${equipos}?page=${page}&limit=${limit}&busqueda=${busqueda}`);

  const debounced = useDebouncedCallback((value) => {
    setBusqueda(value);
    setLimit(null);
  }, 1000);

  const [isOpen, setIsOpen] = useState(false);
  const [equipo, setEquipo] = useState();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <Container maxWidth={"sm"}>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1">
            Equipos
          </Typography>
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" xs={12} lg={12} mt={5} spacing={2}>
          <Grid item xs={12} lg={8}>
            <Paper style={{ borderRadius: 18, padding: "0.2em" }}>
              <Grid item container direction="row" justifyContent="center" alignItems="center" xs={12} lg={12}>
                <IconButton disabled sx={{ p: "10px" }} aria-label="menu">
                  <Icon>app_shortcut</Icon>
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Modelo o marca"
                  inputProps={{ "aria-label": "Modelo o marca" }}
                  onChange={(e: any) => {
                    debounced(e.target.value);
                  }}
                />

                <IconButton sx={{ p: "10px" }} aria-label="menu">
                  <Icon> search</Icon>
                </IconButton>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Button variant="contained" color="primary" sx={{ width: "100%" }} onClick={() => setIsOpen(true)}>
              Nuevo Equipo
            </Button>
          </Grid>
        </Grid>
        <Grid container item xs={12} mt={2}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  {/* <TableCell /> */}
                  <TableCell
                    align="left"
                    sx={{ fontWeight: "bold", cursor: "pointer" }}
                    sortDirection={"asc"}
                  ></TableCell>
                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                    Marca
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                    Modelo
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                    Tipo
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody sx={{}}>
                {loading && (
                  <TableRow>
                    <TableCell align="center" colSpan={6}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}

                {data?.equipos?.map((equipo: any, index: number) => (
                  <TableRow
                    key={index}
                    hover
                    sx={{ "& > *": { borderBottom: "unset" }, cursor: "pointer" }}
                    onClick={() => {
                      setIsOpen(true);
                      setEquipo(equipo);
                    }}
                  >
                    <TableCell component="th" scope="row" width={"10%"}>
                      <img
                        src={equipo.tipo == "Estación total" ? "./estacionTotal.png" : "./nivel.png"}
                        alt="Estación total"
                        width="64px"
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {equipo.marca}
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2" component="p" textAlign={"left"}>
                        {equipo.modelo}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">{equipo.tipo}</TableCell>
                  </TableRow>
                ))}
                {data?.count && limit && (
                  <TablePagination
                    labelRowsPerPage="Equipos por página"
                    labelDisplayedRows={({ from, to, count }) => {
                      return `Del ${from} al ${to} de  ${count !== -1 ? count : `more than ${to}`}`;
                    }}
                    rowsPerPageOptions={[2, 5, 10, 15, 20, 200]}
                    count={data?.count || data?.equipos?.length}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={(event, page) => {
                      setPage(page);
                    }}
                    onRowsPerPageChange={(event) => setLimit(parseInt(event.target.value))}
                  />
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* {data?.count && (
            <TablePagination
              labelRowsPerPage={"Equipos por página" + data?.equipos?.length}
              labelDisplayedRows={({ from, to, count }) => {
                return `Del ${from} al ${to} de  ${count !== -1 ? count : `more than ${to}`}`;
              }}
              rowsPerPageOptions={[2, 5, 10, 15, 20]}
              count={data?.count}
              rowsPerPage={limit}
              page={page}
              onPageChange={(event, page) => {
                setPage(page);
              }}
              onRowsPerPageChange={(event) => setLimit(parseInt(event.target.value))}
            />
          )} */}
        </Grid>
      </Grid>
      <NuevoEquipoModal
        equipoI={equipo}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          setEquipo(undefined);
        }}
      />
    </Container>
  );
};

const NuevoEquipoModal: React.FC<{ equipoI?: any; open: boolean; onClose: any }> = ({ equipoI, open, onClose }) => {
  const [equipo, setEquipo] = useState<any>(
    equipoI || {
      marca: "",
      modelo: "",
      tipo: "",
      especificaciones: {
        // angular: {
        //   tolerancia: [0, 0, 0],
        //   incertidumbre: [0, 0, 0],
        // },
        // distancia: {
        //   tolerancia: null,
        //   toleranciaNoPrism: null,
        //   incertidumbre: null,
        // },
      },
    }
  );

  const { data: marcasList } = useFetch(`${base}${marcas}`);

  const navigate = useNavigate();
  const [alert, setAlert] = useState<any>();

  useEffect(() => {
    if (equipoI) setEquipo(equipoI);
  }, [equipoI]);

  const handleClose = () => {
    setEquipo(undefined);
    setAlert(undefined);
    onClose();
  };

  const eliminarEquipo = async () => {
    fetch(`${base}${equipos}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(equipo),
    })
      .then((res) => res.json())
      .then((e) => {
        setAlert({
          color: "success",
          mensaje: "Equipo eliminado correctamente",
          titulo: `${e.equipo.marca} ${e.equipo.modelo} fue eliminiado`,
        });
        navigate(0);
      })
      .catch((e) => window.alert(e));
  };
  const guardarEquipo = async (update?: boolean) => {
    const url = new URL(`${base}${equipos}`);
    const body = equipo;

    fetch(url.toString(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (!res.ok) throw new Error(res.statusText);

        return res;
      })
      .then((res) => res.json())

      .then((e) => {
        setAlert({ titulo: "Equipo guardado", mensaje: "Se guardó la información de este equipo", color: "success" });
        //reload browser window
        navigate(0);
      })
      .catch((err) => {
        console.error(err);
        setAlert({ titulo: "Error", mensaje: "El equipo no pudo ser guardado", color: "error" });
      });
  };

  return (
    <Dialog open={open} onClose={handleClose} onBackdropClick={handleClose}>
      <DialogTitle>
        <b>Crear equipo nuevo</b>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} my={2}>
            <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Tipo"
              variant="standard"
              sx={{ width: "100%" }}
              defaultValue={equipo?.tipo}
              onChange={(e, value) => {
                setEquipo({ ...equipo, tipo: e.target.value });
              }}
            >
              <MenuItem value={"Estación total"}>Estación total</MenuItem>
              <MenuItem value={"Nivel"}>Nivel</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} my={2}>
            <Autocomplete
              freeSolo
              disablePortal
              disableClearable
              options={marcasList}
              defaultValue={equipo?.marca}
              renderInput={(params: any) => (
                <TextField
                  onChange={(e) => {
                    setEquipo({ ...equipo, marca: e.target.value });
                  }}
                  {...params}
                  label="Marca"
                  variant="standard"
                />
              )}
              onChange={(e: any, value) => {
                setEquipo({ ...equipo, marca: value || e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} my={2}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Modelo"
              type="text"
              fullWidth
              autoComplete="off"
              variant="standard"
              defaultValue={equipo?.modelo}
              onChange={(e) => setEquipo({ ...equipo, modelo: e.target.value })}
            />
          </Grid>
          {equipo?.tipo == "Estación total" ? (
            <>
              {" "}
              <Grid item xs={12} my={2}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container item xs={12} spacing={4}>
                      <Grid item xs={12}>
                        <Typography variant="h5" component="h2">
                          Especificaciones angulares
                        </Typography>
                        <Typography variant="caption" component="h2">
                          En segundos
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="Tolerancia Angular"
                          type="number"
                          fullWidth
                          autoComplete="off"
                          variant="standard"
                          defaultValue={equipo?.especificaciones?.angular?.tolerancia[2] || 0}
                          onChange={(e) => {
                            setEquipo({
                              ...equipo,
                              especificaciones: {
                                ...(equipo?.especificaciones || {}),
                                angular: {
                                  ...(equipo?.especificaciones?.angular || {}),
                                  tolerancia: [0, 0, parseInt(e.target.value)],
                                },
                              },
                            });
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">segundos</InputAdornment>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="Incertidumbre Angular"
                          type="number"
                          fullWidth
                          autoComplete="off"
                          variant="standard"
                          defaultValue={
                            equipo?.especificaciones?.angular?.incertidumbre
                              ? equipo?.especificaciones?.angular?.incertidumbre[2]
                              : 0
                          }
                          onChange={(e) => {
                            setEquipo({
                              ...equipo,
                              especificaciones: {
                                ...equipo?.especificaciones,
                                angular: {
                                  ...equipo?.especificaciones.angular,
                                  incertidumbre: [0, 0, parseInt(e.target.value)],
                                },
                              },
                            });
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">segundos</InputAdornment>,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} my={2}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container item xs={12} spacing={4}>
                      <Grid item xs={12}>
                        <Typography variant="h5" component="h2">
                          Especificaciones de distancia
                        </Typography>
                        <Typography variant="caption" component="h2">
                          En metros
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="Incertidumbre"
                          type="text"
                          fullWidth
                          autoComplete="off"
                          variant="standard"
                          defaultValue={equipo?.especificaciones?.distancia?.incertidumbre}
                          onChange={(e) => {
                            setEquipo({
                              ...equipo,
                              especificaciones: {
                                ...equipo?.especificaciones,
                                distancia: {
                                  ...equipo.especificaciones.distancia,
                                  incertidumbre: e.target.value,
                                },
                              },
                            });
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">m</InputAdornment>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="Tolerancia"
                          type="text"
                          fullWidth
                          autoComplete="off"
                          variant="standard"
                          defaultValue={equipo?.especificaciones?.distancia?.tolerancia}
                          onChange={(e) => {
                            setEquipo({
                              ...equipo,
                              especificaciones: {
                                ...equipo.especificaciones,
                                distancia: {
                                  ...equipo.especificaciones.distancia,
                                  tolerancia: e.target.value,
                                },
                              },
                            });
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">m</InputAdornment>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="Tolerancia sin prisma"
                          type="text"
                          fullWidth
                          autoComplete="off"
                          variant="standard"
                          defaultValue={equipo?.especificaciones?.distancia?.toleranciaNoPrism}
                          onChange={(e) => {
                            setEquipo({
                              ...equipo,
                              especificaciones: {
                                ...equipo.especificaciones,
                                distancia: {
                                  ...equipo.especificaciones.distancia,
                                  toleranciaNoPrism: e.target.value,
                                },
                              },
                            });
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">m</InputAdornment>,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} my={2}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container item xs={12} spacing={4}>
                      <Grid item xs={12}>
                        <Typography variant="h5" component="h2">
                          Especificaciones
                        </Typography>
                        <Typography variant="caption" component="h2"></Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          autoFocus
                          margin="dense"
                          label="Magnificador"
                          type="text"
                          fullWidth
                          autoComplete="off"
                          variant="standard"
                          defaultValue={equipo?.especificaciones?.magnificador}
                          onChange={(e) => {
                            console.log(e);
                            setEquipo({
                              ...equipo,
                              especificaciones: {
                                ...equipo?.especificaciones,
                                magnificador: e.target.value,
                              },
                            });
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">X</InputAdornment>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          autoFocus
                          margin="dense"
                          label="Resolución"
                          type="text"
                          fullWidth
                          autoComplete="off"
                          variant="standard"
                          defaultValue={equipo?.especificaciones?.resolucion}
                          onChange={(e) => {
                            setEquipo({
                              ...equipo,
                              especificaciones: {
                                ...equipo.especificaciones,
                                resolucion: e.target.value,
                              },
                            });
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end"></InputAdornment>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          autoFocus
                          margin="dense"
                          label="Exactitud media"
                          type="text"
                          fullWidth
                          autoComplete="off"
                          variant="standard"
                          defaultValue={equipo?.especificaciones?.exactitudMedia}
                          onChange={(e) => {
                            setEquipo({
                              ...equipo,
                              especificaciones: {
                                ...equipo.especificaciones,
                                exactitudMedia: e.target.value,
                              },
                            });
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">m</InputAdornment>,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
        <br />
        <br /> <br />
        {alert && (
          <Alert severity={alert.color}>
            <AlertTitle>
              <b>{alert.titulo}</b>
            </AlertTitle>
            {alert.mensaje}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={eliminarEquipo} color="error">
          eliminar
        </Button>
        <Button onClick={handleClose}>Cancelar</Button>

        <Button color="primary" variant="contained" onClick={() => guardarEquipo()}>
          Guardar equipo
        </Button>
      </DialogActions>
    </Dialog>
  );
};
