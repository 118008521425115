import React, { useContext } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { ThemeOptions, ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CssBaseline, useMediaQuery } from "@mui/material";
import App from "./App";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { LoginPage } from "./pages/login.page";
import { CertificadosPage } from "./pages/certificados.page";
import { ClientesPage } from "./pages/clientes.page";
import { EquiposPage } from "./pages/equipos.page";
import { TecnicosPage } from "./pages/tecnicos.page";
import { ConfiguracionPage } from "./pages/configuracion.page";
import { AuthContext, AuthProvider } from "./contexts/auth.context";
export const darkTheme: ThemeOptions = {
  palette: {
    mode: "dark",
    background: {
      default: "#282A37",
      paper: "#3D404A",
    },
    primary: {
      main: "#6981ff",
      light: "#6981ff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#f50057",
    },
    error: {
      main: "#fe2659",
    },
    warning: {
      main: "#FFE080",
    },
  },
  shape: {
    borderRadius: 16,
  },
  components: {
    MuiList: {
      styleOverrides: {
        padding: {
          paddingBottom: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: "1rem",
          padding: "0.6rem",
        },
      },
    },
  },
};

export const lightTheme: ThemeOptions = {
  palette: {
    mode: "light",
    background: {
      default: "#FFFFFF",
      paper: "#EEEEEE",
    },
    primary: {
      main: "#375DF6",
    },
    secondary: {
      main: "#f50057",
    },
    error: {
      main: "#fe2659",
    },
    warning: {
      main: "#FFE080",
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: "1rem",
          padding: "0.6rem",
        },
      },
    },
  },
};

function Base() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = React.useMemo(() => createTheme(darkTheme), [prefersDarkMode]);
  const { user } = useContext(AuthContext);
  return (
    <ThemeProvider theme={createTheme(theme)}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          {user ? (
            <Route path="/" element={<App />}>
              <Route index element={<CertificadosPage />} />
              <Route path="clientes" element={<ClientesPage />} />
              <Route path="equipos" element={<EquiposPage />} />
              <Route path="tecnicos" element={<TecnicosPage />} />
              <Route path="certificados" element={<CertificadosPage />} />
              <Route path="configuracion" element={<ConfiguracionPage />} />
            </Route>
          ) : (
            <Route path="*" element={<Navigate to="/login" replace />} />
          )}
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
//create a root react element
ReactDOM.render(
  <AuthProvider>
    <Base />
  </AuthProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
