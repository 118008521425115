import React, { useState, useEffect, useMemo } from "react";

import { TransitionProps } from "@mui/material/transitions";
import {
  Dialog,
  ListItemText,
  ListItem,
  List,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Card,
  CardContent,
  TextField,
  Container,
  Grid,
  Button,
  Menu,
  InputAdornment,
  CardActions,
  Icon,
  Box,
  Autocomplete,
  createFilterOptions,
  Fab,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { MenuItem } from "@mui/material";
import { useFetch } from "../hooks/useFetch.hook";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import moment from "moment";

import routes from "../routes.json";
import { addCommas } from "../utils";
import { CertificadoForm, Angulo } from "../types";
import tablas from "../modals/estacionTotal.json";

const { base, equipos, clientes, certificados } = routes;
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const constantes = {
  angular: {
    patron: [180, 0, 0],
    vertical: {
      fase1: [
        [90, 0, 0],
        [60, 0, 0],
      ],
    },
    horizontal: {
      fase1: [
        [0, 0, 0],
        [90, 0, 0],
      ],
    },
  },
  distancia: {
    patron: [9.825, 14.337],
    fase1: [9.825, 14.337],
  },
};

const nivelCalibracionInitial = {
  previos: {
    temperatura: 0,
    presionAtmosferica: 0,
    BM1: {
      "vista+": 0,
      "vista-": 0,
      cotaAjustada: 0,
      cotaCalculada: 0,
    },
    BM2: {
      "vista+": 0,
      "vista-": 0,
      cotaAjustada: 0,
      cotaCalculada: 0,
    },
  },
  posteriores: {
    temperatura: 0,
    presionAtmosferica: 0,
    BM1: {
      "vista+": 0,
      "vista-": 0,
      cotaAjustada: 0,
      cotaCalculada: 0,
    },
    BM2: {
      "vista+": 0,
      "vista-": 0,
      cotaAjustada: 0,
      cotaCalculada: 0,
    },
  },
};

const estacionTotalCalibracionInitial = {
  previos: {
    temperatura: 0,
    presionAtmosferica: 0,
    distancia: [
      {
        fase1: 9.827,
        fase2: 0,
      },
      {
        fase1: 14.339,
        fase2: 0,
      },
    ],
    angular: {
      vertical: [
        {
          fase1: [0, 0, 0],
          fase2: [0, 0, 0],
        },
        {
          fase1: [0, 0, 0],

          fase2: [0, 0, 0],
        },
      ],
      horizontal: [
        {
          fase1: [0, 0, 0],
          fase2: [0, 0, 0],
        },
        {
          fase1: [0, 0, 0],
          fase2: [0, 0, 0],
        },
      ],
    },
  },
  posteriores: {
    temperatura: 0,
    presionAtmosferica: 0,
    distancia: [
      {
        fase1: 9.827,
        fase2: 0,
      },
      {
        fase1: 14.339,
        fase2: 0,
      },
    ],
    angular: {
      vertical: [
        {
          fase1: [0, 0, 0],
          fase2: [0, 0, 0],
        },
        {
          fase1: [0, 0, 0],

          fase2: [0, 0, 0],
        },
      ],
      horizontal: [
        {
          fase1: [0, 0, 0],
          fase2: [0, 0, 0],
        },
        {
          fase1: [0, 0, 0],
          fase2: [0, 0, 0],
        },
      ],
    },
  },
};
export const NuevoCertificadoModal: React.FC<{
  isOpen: boolean;
  onClose: any;
  certificadoI?: any;
}> = ({ isOpen, onClose, certificadoI }) => {
  const [_id, set_id] = useState(certificadoI?._id);
  const [cliente, setCliente] = useState(certificadoI?.cliente);
  const [equipo, setEquipo] = useState(certificadoI?.equipo);
  const [estado, setEstado] = useState("Borrador");
  const [inspeccion, setInspeccion] = useState(certificadoI?.inspeccion);
  const [fechas, setFechas] = useState(certificadoI?.fechas);
  const [calibracion, setCalibracion] = useState(certificadoI?.calibracion);
  //initialize the form with empty values
  // const [certificado, setCertificado] = useState<CertificadoForm | any>(initial);

  const [emitido, setEmitido] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const handleClose = () => {
    cleanState();
    onClose();
  };

  const cleanState = () => {
    set_id(null);
    setCliente(null);
    setEquipo(null);
    setEstado("Borrador");
    // setCertificado(null);
    setInspeccion(null);
    setEmitido(false);
  };

  useMemo(() => {
    if (certificadoI?._id) {
      set_id(certificadoI?._id);
      setCliente(certificadoI?.cliente);
      setEquipo(certificadoI?.equipo);
      setEstado(certificadoI?.estado);
      setInspeccion(certificadoI?.inspeccion);
      setFechas(certificadoI?.fechas);
      setCalibracion(certificadoI?.calibracion);
      setEmitido(certificadoI?.emitido);
    } else {
      cleanState();

      setFechas({
        ultimoUso: {
          fecha: moment().subtract(1, "day").format("YYYY-MM-DD"),
          lugar: "",
        },
        ingreso: new Date(),
        calibracion: new Date(),
      });
    }
  }, [certificadoI]);

  // useEffect(() => {
  //   if (_id) {
  //     if (equipo) {
  //       if (equipo.modelo != certificadoI?.equipo?.modelo) {
  //       }
  //       console.log("ANTES SWITCH");
  //       switch (equipo.tipo) {
  //         case "Nivel":
  //           setInspeccion(tablas.nivel);
  //           setCalibracion({
  //             previos: {
  //               temperatura: 0,
  //               presionAtmosferica: 0,
  //               BM1: {
  //                 "vista+": 0,
  //                 "vista-": 0,
  //                 cotaAjustada: 0,
  //                 cotaCalculada: 0,
  //               },
  //               BM2: {
  //                 "vista+": 0,
  //                 "vista-": 0,
  //                 cotaAjustada: 0,
  //                 cotaCalculada: 0,
  //               },
  //             },
  //             posteriores: {
  //               temperatura: 0,
  //               presionAtmosferica: 0,
  //               BM1: {
  //                 "vista+": 0,
  //                 "vista-": 0,
  //                 cotaAjustada: 0,
  //                 cotaCalculada: 0,
  //               },
  //               BM2: {
  //                 "vista+": 0,
  //                 "vista-": 0,
  //                 cotaAjustada: 0,
  //                 cotaCalculada: 0,
  //               },
  //             },
  //           });
  //           break;
  //         case "Estación total":
  //           setInspeccion(tablas.estacionTotal);
  //           setCalibracion(c);
  //           break;
  //       }
  //     } else {
  //       setInspeccion(certificadoI?.inspeccion);
  //       setCalibracion(certificadoI?.calibracion);
  //     }
  //   }else{}
  // }, [certificadoI]);

  const enviarCertificado = async () => {
    setAlert({
      open: true,
      message: "Emitiendo certificado...",
      severity: "info",
    });

    let certificado = {
      _id,
      cliente,
      equipo,
      estado,
      inspeccion,
      fechas,
      calibracion,
    };

    fetch(`${base}${certificados}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(certificado),
    })
      .then((res) => res.json())
      .then((data) => {
        setEmitido(true);
        setAlert({
          open: true,
          message: "Certificado emitido",
          severity: "success",
        });
      })
      .catch((err) => {
        setAlert({
          open: true,
          message: "Error al emitir certificado",
          severity: "error",
        });
        console.error(err);
      });
  };
  const [validacion, setValidacion] = useState<{
    informacionParaCalibracion: boolean;
    cliente: boolean;
    equipo: boolean;
    inspeccion: boolean;
    calibracion: boolean;
  }>({
    informacionParaCalibracion: false,
    cliente: false,
    equipo: false,
    inspeccion: false,
    calibracion: false,
  });

  // useEffect(() => {
  //   if (!calibracion || !cliente || !equipo || !fechas || !inspeccion) return;
  //   setValidacion({
  //     informacionParaCalibracion: !!(
  //       fechas?.ultimoUso?.fecha &&
  //       fechas?.ultimoUso?.lugar &&
  //       fechas?.ingreso &&
  //       fechas?.calibracion
  //     ),
  //     cliente: !!cliente,
  //     equipo: !!equipo && !!equipo.modelo && !!equipo.serial,

  //     inspeccion:
  //       inspeccion.filter(
  //         (punto: any) =>
  //           punto?.lista?.filter(
  //             (item: any) => !item.resultado || item?.resultado === ""
  //           ).length > 0
  //       ).length === 0,

  //     //checks if  calibracion[resultado].temperatura is not null
  //     calibracion:
  //       !!calibracion?.previos?.temperatura &&
  //       !!calibracion?.posteriores?.temperatura &&
  //       !!calibracion?.previos?.presionAtmosferica &&
  //       !!calibracion?.posteriores?.presionAtmosferica &&
  //       !!calibracion?.posteriores?.fechaMuestreo &&
  //       calibracion?.previos?.angular?.vertical.filter(
  //         (vertical: any) =>
  //           vertical?.fase2?.filter((index: any) => isNaN(index)).length > 0
  //       ).length === 0 &&
  //       calibracion?.previos?.angular?.horizontal.filter(
  //         (vertical: any) =>
  //           vertical?.fase2?.filter((index: any) => isNaN(index)).length > 0
  //       ).length === 0 &&
  //       calibracion?.posteriores?.angular?.vertical.filter(
  //         (vertical: any) =>
  //           vertical?.fase2?.filter((index: any) => isNaN(index)).length > 0
  //       ).length === 0 &&
  //       calibracion?.posteriores?.angular?.horizontal.filter(
  //         (vertical: any) =>
  //           vertical?.fase2?.filter((index: any) => isNaN(index)).length > 0
  //       ).length === 0,
  //   });
  // }, [calibracion, cliente, equipo, fechas, inspeccion]);

  return (
    <Dialog
      fullScreen
      disableEscapeKeyDown
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {_id ? "Actualizar" : "Crear nuevo"} certificado{" "}
            {_id ? `No. ` + certificadoI?.numero : ""}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <Icon>close</Icon>
          </IconButton>
        </Toolbar>
      </AppBar>
      {alert.open && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          autoHideDuration={2000}
          onClose={() => {
            setAlert({ open: false, message: "", severity: "info" });
          }}
        >
          <Alert
            onClose={() => {
              setAlert({ open: false, message: "", severity: "info" });
            }}
            severity={alert?.severity as any}
            sx={{ width: "100%" }}
          >
            {alert?.message}
          </Alert>
        </Snackbar>
      )}
      <Container maxWidth="md">
        <Grid container spacing={3} mt={4}>
          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            xs={12}
            spacing={3}
          >
            <Grid item xs={12} md={6} lg={6}>
              <EquipoCard
                equipoI={equipo}
                onChange={(equipoN) => {
                  setEquipo(equipoN);

                  if (_id) {
                    if (equipoN?.modelo === certificadoI?.equipo?.modelo) {
                      setCalibracion(certificadoI?.calibracion);
                      setInspeccion(certificadoI?.inspeccion);
                      return;
                    }
                  }

                  switch (equipoN?.tipo) {
                    case "Nivel":
                      setCalibracion(nivelCalibracionInitial);
                      setInspeccion(tablas.nivel);
                      break;
                    case "Estación total":
                      setCalibracion(estacionTotalCalibracionInitial);
                      setInspeccion(tablas.estacionTotal);
                      break;
                    default:
                      setCalibracion(undefined);
                      setInspeccion(undefined);
                      break;
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <ClienteCard
                valid={validacion?.cliente}
                clienteI={cliente}
                onChange={setCliente}
              />
            </Grid>
          </Grid>
          <InformacionParaCalibracion
            valid={validacion?.informacionParaCalibracion}
            fechasI={fechas}
            onChange={setFechas}
          />

          {
            // MARK: Inspección
          }
          <Grid item xs={12} lg={12} mt={8}>
            <Typography variant="h4" component="h2">
              Inspección
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {equipo && inspeccion && (
              <InspeccionCard
                inspeccionI={inspeccion}
                onChange={setInspeccion}
              />
            )}
          </Grid>

          <Grid item xs={12} lg={12} mt={8}>
            <Typography variant="h4" component="h2">
              Procedimiento de calibración
            </Typography>
          </Grid>
          <Grid item xs={12} mb={4}>
            {equipo &&
              calibracion &&
              ["previos", "posteriores"].map((resultado, index) => (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Resultados {resultado}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid
                      item
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="center"
                      alignItems="stretch"
                      xs={12}
                    >
                      {
                        //MARK: Resultados previos
                      }
                      <Grid item xs={12} lg={12}>
                        <Typography variant="h5" component="h2">
                          Resultados {resultado}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} lg={12}></Grid>
                      {
                        // MARK: Medicion angular
                      }
                      {equipo?.tipo == "Estación total" ? (
                        <>
                          <CalibracionAngular
                            resultado={resultado}
                            calibracionI={calibracion}
                            onChange={(calibracion) => {
                              setCalibracion((prev) => ({
                                ...prev,
                                [resultado]: {
                                  ...calibracion[resultado],
                                },
                              }));
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <CalibracionNivel
                            resultado={resultado}
                            calibracionI={calibracion}
                            onChange={(calibracion) => {
                              setCalibracion((prev) => ({
                                ...prev,
                                [resultado]: {
                                  ...calibracion[resultado],
                                },
                              }));
                            }}
                          />
                        </>
                      )}

                      {/* {(
                      constantes.angular.horizontal.fase1.map((e) => ({
                        patron: constantes.angular.patron,
                        fase1: e,
                      })) as { patron: Angulo; fase1: Angulo }[]
                    ).map((medicion, index) => (
                      <Grid item xs={12} lg={6} key={index}>
                        <AngularCard
                          titulo={"Angular Horizontal"}
                          fase1={medicion.fase1}
                          fase2={calibracion[resultado]?.angular?.horizontal[index]?.fase2}
                          patron={medicion.patron}
                          onChange={(fase2: Angulo) => {
                            let temp = certificado.calibracion[resultado].angular.horizontal;
                            temp[index] = { fase1: medicion.fase1, fase2: fase2, patron: medicion.patron };

                            setCertificado({
                              ...certificado,
                              resultados: {
                                ...certificado.resultados,
                                previos: {
                                  ...certificado.calibracion.previos,
                                  angular: {
                                    ...certificado.calibracion[resultado].angular,
                                    horizontal: temp,
                                  },
                                },
                              },
                            });
                          }}
                        />
                      </Grid>
                    ))}
                     */}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Grid>

          <Fab
            style={{ position: "fixed", right: "2rem", bottom: "1rem" }}
            color={"primary"}
            variant="extended"
            onClick={() => {
              enviarCertificado();
            }}
            disabled={
              // (() => {
              //   //iterate all properties in validacion and if any is false return false
              //   for (let prop in validacion) {
              //     if (!validacion[prop]) {
              //       return true;
              //     }
              //   }
              // })() ||
              // emitido ||
              false
            }
          >
            <Icon sx={{ mr: 1 }}>send</Icon>
            {emitido ? "Emitido" : "Emitir certificado"}
          </Fab>
        </Grid>
      </Container>
    </Dialog>
  );
};

const Angulo_toString = (angulo: Angulo) => {
  return `${angulo[0]}°${angulo[1]}´${angulo[2]}¨`;
};

const DistanciaCard: React.FC<{
  patron: number;
  titulo: string;
  onChange: any;
  fase1: number;
  fase2?: number;
}> = ({ titulo, patron, fase1, fase2, onChange }) => {
  const [medida, setMedida] = useState<number>(fase2 || 0);
  useEffect(() => {
    if (medida) onChange(medida);
  }, [medida]);
  return (
    <Card variant="outlined">
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={8}>
            <Typography variant="h5" component="h2">
              {titulo}
            </Typography>
            <Typography variant="caption" component="h2">
              Patrón: <b>{patron}m</b>
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "right" }}>
            <Typography variant="body1" component="h2">
              <b>{fase1}m</b>
            </Typography>
            <Typography variant="caption" component="h2">
              <b>Fase 1</b>
            </Typography>
          </Grid>
        </Grid>

        <Divider />

        <Grid container spacing={3} mt={1}>
          <Grid item xs={12}>
            <Typography variant="body1" component="h2">
              <b>Fase 2</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">m</InputAdornment>
                ),
              }}
              label="Distancia"
              variant="outlined"
              type="number"
              value={medida}
              onChange={(e: any) => {
                setMedida(parseFloat(e.target.value));
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const AngularCard: React.FC<{
  patron: Angulo;
  titulo: string;
  onChange: any;
  fase1: Angulo;
  fase2?: Angulo;
}> = ({ titulo, patron, fase1, fase2, onChange }) => {
  const [angulo, setAngulo] = useState<Angulo>(fase2 ? fase2 : [0, 0, 0]);
  useEffect(() => {
    onChange(angulo);
  }, [angulo]);
  return (
    <Card variant="outlined">
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={8}>
            <Typography variant="h5" component="h2">
              {titulo}
            </Typography>
            <Typography variant="caption" component="h2">
              Patrón: <b>{Angulo_toString(patron)}</b>
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "right" }}>
            <Typography variant="body1" component="h2">
              <b>{Angulo_toString(fase1)}</b>
            </Typography>
            <Typography variant="caption" component="h2">
              <b>Fase 1</b>
            </Typography>
          </Grid>
        </Grid>

        <Divider />

        <Grid container spacing={3} mt={1}>
          <Grid item xs={12}>
            <Typography variant="body1" component="h2">
              <b>Fase 2</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">°</InputAdornment>
                ),
              }}
              label="Grados"
              variant="outlined"
              type="number"
              value={angulo[0]}
              onChange={(e: any) => {
                setAngulo([parseInt(e.target.value), angulo[1], angulo[2]]);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">'</InputAdornment>
                ),
              }}
              label="Minutos"
              variant="outlined"
              type="number"
              value={angulo[1]}
              onChange={(e: any) => {
                setAngulo([angulo[0], parseInt(e.target.value), angulo[2]]);
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">"</InputAdornment>
                ),
              }}
              label="Segundos"
              variant="outlined"
              type="number"
              value={angulo[2]}
              onChange={(e: any) => {
                setAngulo([angulo[0], angulo[1], parseInt(e.target.value)]);
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const Checklist: React.FC<{
  valid: boolean;
  titulo: string;
  list: Array<{ punto: string; resultado: any }>;
  onChange: any;
}> = ({ titulo, valid, list, onChange }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography sx={{ width: "96%", flexShrink: 0 }}>{titulo}</Typography>

        {valid && <Icon sx={{ color: "#37F683" }}>done</Icon>}
      </AccordionSummary>
      <AccordionDetails>
        {list.map((elemento, index) => (
          <List dense={true} key={index}>
            <Card variant="outlined">
              <ListItem
                secondaryAction={
                  <BasicMenu
                    value={elemento?.resultado}
                    onChange={(value: any) => {
                      let temp = list;
                      temp[index].resultado = value;
                      onChange([...temp]);
                    }}
                  />
                }
              >
                <ListItemText
                  primary={
                    <Typography variant="h6">{elemento.punto}</Typography>
                  }
                  sx={{ mb: 4 }}
                />
              </ListItem>
            </Card>
          </List>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
const BasicMenu: React.FC<{ onChange: any; value: string }> = ({
  onChange,
  value,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (value: any) => {
    setAnchorEl(null);
    if (typeof value === "string") onChange(value);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="contained"
      >
        {value}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleClose("OK")}>
          <b>OK</b>: Correcto Funcionamiento
        </MenuItem>
        <MenuItem onClick={() => handleClose("P")}>
          <b>P</b>: Mantenimiento preventivo
        </MenuItem>
        <MenuItem onClick={() => handleClose("C")}>
          <b>C</b>: Mantenimiento correctivo
        </MenuItem>
        <MenuItem onClick={() => handleClose("A")}>
          <b>A</b>: Adecuación de constantes
        </MenuItem>
        <MenuItem onClick={() => handleClose("AP")}>
          <b>AP</b>: Adecuación al patrón
        </MenuItem>
        <MenuItem onClick={() => handleClose("N/A")}>
          <b>N/A</b>: No aplica
        </MenuItem>
      </Menu>
    </div>
  );
};
const InspeccionCard: React.FC<{
  inspeccionI: any;
  onChange: any;
}> = ({ inspeccionI, onChange }) => {
  const [inspeccion, setInspeccion] = useState<any>(inspeccionI);
  {
    useEffect(() => {
      onChange(inspeccion);
    }, [inspeccion]);

    return (
      <>
        {inspeccion?.map((tabla: any, index: number) => (
          <Checklist
            key={index}
            valid={
              tabla?.lista?.filter(
                (item: any) => !item.resultado || item?.resultado === ""
              ).length === 0
            }
            titulo={`${index + 1}. ${tabla.titulo}`}
            list={tabla.lista}
            onChange={(value) => {
              console.log(value);
              setInspeccion((prev) =>
                prev.map((tabla, i) =>
                  i !== index
                    ? tabla
                    : {
                        ...tabla,
                        lista: value,
                      }
                )
              );
            }}
          />
        ))}
      </>
    );
  }
};
const EquipoCard: React.FC<{ equipoI?: any; onChange: any }> = ({
  onChange,
  equipoI,
}) => {
  const [equipo, setEquipo] = useState<any>({ ...equipoI });

  useEffect(() => {
    onChange(equipo);
  }, [equipo]);

  const { data, loading } = useFetch(`${base}${equipos} `);

  return (
    <Card style={{ height: "100%" }}>
      {equipo?.modelo ? (
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item xs={6}>
              <img
                src={
                  equipo.tipo === "Nivel" ? "/nivel.png" : "/estacionTotal.png"
                }
                style={{ width: "100%" }}
                alt="Estacion Total"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5">{equipo.modelo}</Typography>
              <Typography variant="caption">{equipo.marca}</Typography>
              <Typography variant="h6">{equipo.tipo}</Typography>

              <TextField
                autoFocus
                margin="dense"
                label="Serial"
                type="text"
                fullWidth
                variant="standard"
                defaultValue={equipo.serial}
                onChange={(e: any) =>
                  setEquipo((prev: any) => ({
                    ...prev,
                    serial: e.target.value,
                  }))
                }
              />
              <CardActions>
                <Button
                  fullWidth
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => {
                    setEquipo(undefined);
                  }}
                >
                  Cambiar equipo
                </Button>
              </CardActions>
            </Grid>
          </Grid>
        </CardContent>
      ) : (
        <CardContent style={{ height: "100%" }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
            textAlign="center"
          >
            <Grid item justifyContent="center" alignItems="center">
              <Box>
                <Icon style={{ fontSize: "3em" }}>add_to_queue</Icon>
                <Typography variant="h5" component="h2">
                  Seleccione un equipo
                </Typography>

                {loading && <CircularProgress />}
                {data?.equipos && (
                  <Autocomplete
                    options={data?.equipos}
                    disableListWrap
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        label="Modelo"
                        variant="standard"
                      />
                    )}
                    limitTags={1000}
                    onChange={(e: any, value) => {
                      setEquipo(value);
                    }}
                    getOptionLabel={(option: {
                      modelo: string;
                      _id: string;
                      marca: string;
                      tipo: string;
                    }) => option.modelo}
                    renderOption={(props, option) => (
                      <Box
                        key={option._id}
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={
                            option.tipo === "Nivel"
                              ? "/nivel.png"
                              : "/estacionTotal.png"
                          }
                          alt="Tipo"
                        />
                        <Typography variant="caption">
                          {option.marca} ({option.modelo})
                        </Typography>
                      </Box>
                    )}
                    renderGroup={(params) => params}
                  />
                )}
              </Box>
            </Grid>
          </Grid>{" "}
        </CardContent>
      )}
    </Card>
  );
};

const ClienteCard: React.FC<{
  valid: boolean;
  clienteI?: any;
  onChange: any;
}> = ({ clienteI, onChange, valid }) => {
  const [cliente, setCliente] = useState<any | undefined>(clienteI);
  const { data, loading } = useFetch(`${base}${clientes} `);
  // useEffect(() => {
  //   if (clienteI) setCliente(clienteI);
  // }, [clienteI]);

  useEffect(() => {
    onChange(cliente);
  }, [cliente]);

  return (
    <Card style={{ height: "100%" }}>
      {cliente?._id ? (
        <CardContent style={{ height: "100%" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item xs={1}>
              {/* <img src="./estacionTotal.png" alt="Estacion Total" /> */}
            </Grid>
            <Grid item xs={11} alignItems="center">
              <Typography variant="h5" component="h2">
                {cliente?.nombre}
              </Typography>
              <Typography variant="caption" component="h2">
                NIT {addCommas(cliente?.NIT)}
              </Typography>
              <Typography variant="caption" component="h2">
                {cliente?.email}
              </Typography>
              <Typography variant="caption" component="h2">
                {cliente?.telefono}
              </Typography>
              <CardActions>
                <Button
                  fullWidth
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => {
                    setCliente(undefined);
                  }}
                >
                  Cambiar cliente
                </Button>
              </CardActions>
            </Grid>
          </Grid>{" "}
        </CardContent>
      ) : (
        <CardContent style={{ height: "100%", cursor: "pointer" }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
            textAlign="center"
          >
            <Grid item justifyContent="center" alignItems="center">
              <Box>
                <Icon style={{ fontSize: "3em" }}>person_add</Icon>
                <Typography variant="h5" component="h2">
                  Seleccione un cliente
                </Typography>
              </Box>

              {loading && <CircularProgress />}
              {data?.clientes && (
                <Autocomplete
                  filterOptions={createFilterOptions({
                    stringify: ({ nombre, NIT }) => `${nombre} ${NIT}`,
                  })}
                  filterSelectedOptions
                  options={data?.clientes}
                  disableListWrap
                  defaultValue={cliente}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="Clientes"
                      variant="standard"
                    />
                  )}
                  limitTags={1000}
                  onChange={(e: any, value) => {
                    setCliente(value);
                  }}
                  getOptionLabel={(option) => option.nombre}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option._id}>
                      <Typography variant="caption">
                        {option?.nombre} {addCommas(option?.NIT)}
                      </Typography>
                    </Box>
                  )}
                  renderGroup={(params) => params}
                />
              )}
            </Grid>
          </Grid>{" "}
        </CardContent>
      )}
    </Card>
  );
};

const CalibracionNivel: React.FC<{
  calibracionI?: any;
  resultado: string | "previos" | "posteriores";
  onChange: any;
}> = ({ onChange, calibracionI, resultado }) => {
  const [calibracion, setCalibracion] = useState<any>(calibracionI);
  useEffect(() => {
    onChange(calibracion);
    console.log("Dentro", calibracion);
  }, [calibracion]);

  return (
    <>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" component="h2">
                  Datos ambientales
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">°C</InputAdornment>
                    ),
                  }}
                  label="Temperatura"
                  variant="outlined"
                  type="number"
                  value={calibracion[resultado]?.temperatura}
                  onChange={(e: any) => {
                    console.log("TEMPERATURA ", calibracion);
                    setCalibracion({
                      ...calibracion,
                      [resultado]: {
                        ...calibracion[resultado],
                        temperatura: parseFloat(e.target.value),
                      },
                    });
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">hPa</InputAdornment>
                    ),
                  }}
                  label="Presión atmosférica"
                  variant="outlined"
                  type="number"
                  value={
                    calibracion[resultado] &&
                    calibracion[resultado]?.presionAtmosferica
                  }
                  onChange={(e: any) => {
                    setCalibracion({
                      ...calibracion,
                      [resultado]: {
                        ...calibracion[resultado],
                        presionAtmosferica: e.target.value,
                      },
                    });
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  sx={{ width: "100%" }}
                  label="Fecha de muestreo"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={
                    calibracion[resultado].fechaMuestreo
                      ? moment(calibracion[resultado].fechaMuestreo).format(
                          "YYYY-MM-DD"
                        )
                      : undefined
                  }
                  onChange={(e: any) => {
                    setCalibracion({
                      ...calibracion,
                      [resultado]: {
                        ...calibracion[resultado],
                        fechaMuestreo: new Date(e.target.value).toISOString(),
                      },
                    });
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {["BM1", "BM2"].map((punto, index) => (
        <Grid item xs={12} key={index}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" component="h2">
                    Punto de control {punto}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">m</InputAdornment>
                      ),
                    }}
                    label="Vista +"
                    autoComplete={"off"}
                    variant="outlined"
                    type="number"
                    // inputProps={{ inputMode: "decimal", pattern: "[0-9]*" }}
                    value={calibracion[resultado][punto]["vista+"]}
                    onChange={(e: any) => {
                      setCalibracion({
                        ...calibracion,
                        [resultado]: {
                          ...calibracion[resultado],
                          [punto]: {
                            ...calibracion[resultado][punto],
                            "vista+": parseFloat(e.target.value),
                          },
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">m</InputAdornment>
                      ),
                    }}
                    label={"Vista -"}
                    autoComplete={"off"}
                    variant="outlined"
                    type="number"
                    value={calibracion[resultado][punto]["vista-"]}
                    onChange={(e: any) => {
                      console.log(calibracion);
                      setCalibracion({
                        ...calibracion,
                        [resultado]: {
                          ...calibracion[resultado],
                          [punto]: {
                            ...calibracion[resultado][punto],
                            "vista-": parseFloat(e.target.value),
                          },
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">m</InputAdornment>
                      ),
                    }}
                    sx={{ width: "100%" }}
                    label="Cota ajustada"
                    variant="outlined"
                    type="number"
                    autoComplete={"off"}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={calibracion[resultado][punto].cotaAjustada}
                    onChange={(e: any) => {
                      setCalibracion({
                        ...calibracion,
                        [resultado]: {
                          ...calibracion[resultado],
                          [punto]: {
                            ...calibracion[resultado][punto],
                            cotaAjustada: parseFloat(e.target.value),
                          },
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    sx={{ width: "100%" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">m</InputAdornment>
                      ),
                    }}
                    label="Cota calculada"
                    autoComplete={"off"}
                    variant="outlined"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={
                      calibracion[resultado] &&
                      calibracion[resultado][punto].cotaAjustada
                    }
                    onChange={(e: any) => {
                      setCalibracion({
                        ...calibracion,
                        [resultado]: {
                          ...calibracion[resultado],
                          [punto]: {
                            ...calibracion[resultado][punto],
                            cotaCalculada: parseFloat(e.target.value),
                          },
                        },
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </>
  );
};

const CalibracionAngular: React.FC<{
  calibracionI?: any;
  resultado: string | "previos" | "posteriores";
  onChange: any;
}> = ({ calibracionI, resultado, onChange }) => {
  const [calibracion, setCalibracion] = useState<any | undefined>(calibracionI);
  useEffect(() => {
    onChange(calibracion);
  }, [calibracion]);
  return (
    <>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" component="h2">
                  Datos ambientales
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">°C</InputAdornment>
                    ),
                  }}
                  label="Temperatura"
                  variant="outlined"
                  type="number"
                  value={calibracion[resultado]?.temperatura}
                  onChange={(e: any) => {
                    console.log("TEMPERATURA ", calibracion);
                    setCalibracion((prev) => ({
                      ...prev,
                      [resultado]: {
                        ...prev[resultado],
                        temperatura: parseFloat(e.target.value),
                      },
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">hPa</InputAdornment>
                    ),
                  }}
                  label="Presión atmosférica"
                  variant="outlined"
                  type="number"
                  value={
                    calibracion[resultado] &&
                    calibracion[resultado]?.presionAtmosferica
                  }
                  onChange={(e: any) => {
                    setCalibracion((prev) => ({
                      ...prev,
                      [resultado]: {
                        ...prev[resultado],
                        presionAtmosferica: parseFloat(e.target.value),
                      },
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  sx={{ width: "100%" }}
                  label="Fecha de muestreo"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={
                    calibracion[resultado].fechaMuestreo
                      ? moment(calibracion[resultado].fechaMuestreo).format(
                          "YYYY-MM-DD"
                        )
                      : undefined
                  }
                  onChange={(e: any) => {
                    setCalibracion((prev) => ({
                      ...prev,
                      [resultado]: {
                        ...prev[resultado],
                        fechaMuestreo: new Date(e.target.value).toISOString(),
                      },
                    }));
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {["vertical", "horizontal"].map((modo) =>
        (
          constantes.angular[modo].fase1.map((fase1) => ({
            patron: constantes.angular.patron,
            fase1: fase1,
          })) as Array<{ patron: Angulo; fase1: Angulo }>
        ).map((medicion, index) => (
          <Grid item xs={12} lg={6} key={index}>
            <AngularCard
              titulo={"Angular " + modo}
              fase1={medicion.fase1}
              fase2={calibracion[resultado]?.angular?.vertical[index]?.fase2}
              patron={medicion.patron}
              onChange={(fase2: Angulo) => {
                let temp = calibracion[resultado].angular[modo];
                temp[index] = {
                  fase1: medicion.fase1,
                  fase2: fase2,
                  patron: medicion.patron,
                };
                setCalibracion((prev) => ({
                  ...prev,
                  [resultado]: {
                    ...prev[resultado],
                    angular: {
                      ...prev[resultado].angular,
                      [modo]: temp,
                    },
                  },
                }));
              }}
            />
          </Grid>
        ))
      )}

      {constantes.distancia.fase1.map((fase1, index) => {
        return (
          <Grid item xs={6} key={index}>
            <DistanciaCard
              titulo="Distancia Horizontal"
              fase1={fase1}
              fase2={calibracion[resultado]?.distancia[index]?.fase2}
              onChange={(fase2: number) => {
                let temp = calibracion[resultado].distancia;
                temp[index] = {
                  fase1: fase1,
                  fase2: fase2,
                  patron: constantes.distancia.patron[index],
                };

                console.log(calibracion[resultado].distancia);
                setCalibracion((prev) => ({
                  ...prev,
                  [resultado]: {
                    ...prev[resultado],
                    distancia: temp,
                  },
                }));
              }}
              patron={constantes.distancia.patron[index]}
            />
          </Grid>
        );
      })}
    </>
  );
};

const InformacionParaCalibracion: React.FC<{
  fechasI: any;
  onChange;
  valid: boolean;
}> = ({ fechasI, onChange, valid }) => {
  const [fechas, setFechas] = useState<any>(fechasI);
  useEffect(() => {
    if (fechas) onChange(fechas);
  }, [fechas]);

  return (
    <Grid item xs={12} lg={12}>
      <Card sx={{ position: "relative" }}>
        <CardContent>
          {valid && (
            <Box
              sx={{
                zIndex: 2,
                color: "#37F683",
                position: "absolute",
                right: "1em",
                top: "1em",
              }}
            >
              <Icon>done</Icon>
            </Box>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2">
                Información para la calibración
              </Typography>
            </Grid>

            <Grid item xs={12} lg={6}>
              <TextField
                sx={{ width: "100%" }}
                label="Fecha de último uso"
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={
                  fechas.ultimoUso.fecha
                    ? moment(fechas.ultimoUso.fecha).format("YYYY-MM-DD")
                    : undefined
                }
                onChange={(e: any) => {
                  setFechas({
                    ...fechas,
                    ultimoUso: {
                      ...fechas.ultimoUso,
                      fecha: new Date(e.target.value).toISOString(),
                    },
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                sx={{ width: "100%" }}
                label="Lugar de último uso"
                variant="outlined"
                type="text"
                defaultValue={fechas.ultimoUso.lugar}
                onChange={(e: any) => {
                  setFechas({
                    ...fechas,
                    ultimoUso: { ...fechas.ultimoUso, lugar: e.target.value },
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                sx={{ width: "100%" }}
                label="Fecha de ingreso al laboratorio"
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={
                  fechas?.ingreso
                    ? moment(fechas?.ingreso).format("YYYY-MM-DD")
                    : undefined
                }
                onChange={(e: any) => {
                  setFechas({
                    ...fechas,
                    ingreso: new Date(e.target.value).toISOString(),
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                sx={{ width: "100%" }}
                label="Fecha de calibración"
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={
                  fechas?.calibracion
                    ? moment(fechas?.calibracion).format("YYYY-MM-DD")
                    : undefined
                }
                onChange={(e: any) => {
                  setFechas({
                    ...fechas,
                    calibracion: new Date(e.target.value).toISOString(),
                  });
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
