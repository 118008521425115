import { useEffect, useState } from "react";

//create a hook to fetch a url with loading and error states
export const useFetch = (
  url: string,
  params: {
    fetchData?: any;
    autoCall?: boolean;
  } = {
    autoCall: true,
  }
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<any>(null);
  const { fetchData, autoCall } = params;

  useEffect(() => {
    const call = () => {
      setLoading(true);
      fetch(url, {
        ...fetchData, //adds jwt token to the request
        headers: {
          ...fetchData?.headers,
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setData(data);
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    };
    if (autoCall) {
      call();
    }
  }, [autoCall, fetchData, url]);

  return { loading, error, data, fetch };
};
