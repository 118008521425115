import routes from "./routes.json";

const { base, clientePorNIT } = routes;

export const consultarNIT = (NIT: number) => {
  var url = new URL(`${base}${clientePorNIT}`);
  url.searchParams.set("NIT", NIT.toString());
  return fetch(url.toString()).then((response) => {
    return response.json();
  });
};

export const addCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
