import React from "react";
import logo from "./logo.svg";
import "./App.scss";
import { Typography } from "@mui/material";
import MiniDrawer from "./pages/base";
import ResponsiveDrawer from "./pages/drawer";

function App() {
  console.log("Version: ", "1.0.2");
  return <MiniDrawer />;
}

export default App;
